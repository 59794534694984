import React from "react";
import styled from 'styled-components';
import { format } from 'date-fns';

export const DateContainer = styled.div`
font-size: 12pt;
margin: 5px 0px;
text-transform: uppercase;
font-weight: 400;
font-family: "Roboto";
letter-spacing: 1px;
`;

const DateFormatter = ({ date, nullValue = null }) => <span>{date ? format(date, 'MMM-YYYY') : nullValue}</span>;

export default DateFormatter